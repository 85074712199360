<template>
  <div :class="(errMessage.length) || hasErr ? 'errItem':''">
    <b-row :class="itemStyle">
      <b-col
        v-if="!noLabel"
        :sm="labelSize" class="right">
        <div :class="disabled?'badgeWrap pale':'badgeWrap'">
          <div v-if="isRequired" class="mr-2 badge badge-required">必須</div>
        </div>
        <label>{{ label }}</label>
      </b-col>
      <v-calendar
        :value="timeObj.ymd"
        @selected="changeData('ymd', $event)"
        :format="DatePickerFormat"
        :language="ja"
        :typeable="true"
        :input-class="'calendar ' + inputClass"
        :disabled="disabled"
        :open-date="setNewDate"
        ref="picker"
        :calendar-button="true"
        calendar-button-icon="calendar-datetime-hide-button"
      />
      <unicon name="calendar-alt"
        fill="#495057"
        :class="iconClass"
        ></unicon>
      <b-col v-if="!noTime" :sm="selectWrapSize" class="px-0 mr-1">
        <b-input-group
          :class="[ymdWrapClass]">
          <b-form-select
            :value="timeObj.time"
            @change="changeData('time', $event)"
            :class="['datetime', inputClass]"
            :disabled="disabled"
            :options="timeOption"/>
          <b-input-group-append
            class="ml-1 flex flexMiddle">
            時
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        v-if="!noMinute"
        :sm="selectWrapSize"
        class="px-0">
        <b-input-group
          :class="[ymdWrapClass]">
          <b-form-select
            :value="timeObj.minute"
            @change="changeData('minute', $event)"
            :class="['datetime', inputClass]"
            :disabled="disabled"
            :options="minuteOption"/>
          <b-input-group-append
            class="ml-1 flex flexMiddle">
            分
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <span class="error" v-if="errMessage">
      {{ errMessage[0] }}
    </span>
  </div>
</template>
<script>
import moment from 'moment';
import datepicker from 'vuejs-datepicker';
import { ja } from 'vuejs-datepicker/dist/locale';
import TimeOptionHelper from '@/modules/TimeSelectBoxOption';

export default {
  name: 'CalendarDatetimeBox',
  components: {
    'v-calendar': datepicker,
  },
  props: {
    label: {},
    labelSize: { type: Number, default: 3 },
    itemStyle: {},
    value: { type: String, default: null },
    inputClass: {},
    selectWrapSize: { type: String, default: '2' },
    disabled: { type: Boolean, default: false },
    hasErr: { type: Boolean, default: false },
    errMessage: { type: Array, default: () => [] },
    isRequired: { type: Boolean, default: false },
    noLabel: { type: Boolean, default: false },
    noMinute: { type: Boolean, default: false },
    ymdWrapClass: { type: String, default: '' },
    updateParam: { type: Object, default: () => {} },
    canPast: { type: Boolean, default: true },
    iconClass: { type: String, default: 'calendar-datetime-icon' },
    openDate: { type: String, default: null },
    isOpenSet: { type: Boolean, default: false },
    groupName: { type: String, default: null },
    noTime: { type: Boolean, default: false },
    isTimeSend: { type: Boolean, default: false },
    isDateOnlySend: { type: Boolean, default: false },
  },
  data() {
    return {
      DatePickerFormat: 'yyyy/MM/dd',
      ja,
      timeObj: {
        ymd: '',
        time: '',
        minute: '',
        second: '00',
      },
    };
  },
  watch: {
    value() {
      this.setData(this.value);
    },
    'timeObj.ymd': function (val) {
      if (this.isOpenSet) {
        const params = {
          value: val,
          group: this.groupName,
        };
        this.$emit('setOpenDate', params);
      }
    },
  },
  methods: {
    async changeData(keyName, eValue) {
      let value = eValue;
      // カレンダーの場合は余計な要素が多数ついているのでYmdだけ取得する
      if (keyName === 'ymd' && this.openDate) {
        if (this.updateParam) {
          const params = {
            index: this.updateParam.index,
            value: eValue,
          };
          this.$emit('ymdChange', params);
        } else {
          this.$emit('ymdChange', eValue);
        }
      }

      if (keyName === 'ymd') {
        value = moment(eValue).format('YYYY/MM/DD');
      }
      if (this.noTime && keyName === 'ymd' && !eValue) {
        this.$set(this.timeObj, keyName, '');
        const param = this.updateParam ? Object.assign(this.updateParam) : {};
        param.value = '';
        this.$emit('change', param);
        return;
      }
      // localの各値にセットする。
      this.$set(this.timeObj, keyName, value);
      // すべての日付が入力されているかチェックする
      const emptyKey = Object.keys(this.timeObj).find((key) => {
        return this.timeObj[key] === '';
      });
      const data = this.timeObj;
      // 時間の送信がtrueの場合は、時間のみ送信
      if (this.isTimeSend) {
        const param = this.updateParam ? Object.assign(this.updateParam) : {};
        const strTimeValue = `${data.time}:${data.minute}:${data.second}`;
        if (moment(strTimeValue, 'HH:mm').isValid() && emptyKey !== 'time') {
          param.value = strTimeValue;
          this.$emit('timeChange', param);
        }
      }
      // 日付のみ送信
      if (this.isDateOnlySend) {
        const param = this.updateParam ? Object.assign(this.updateParam) : {};
        param.value = data.ymd;
        if (moment(data.ymd, 'YYYY/MM/DD').isValid() && emptyKey !== 'ymd') {
          this.$emit('dateChange', param);
        }
      }

      // セットされていない値があれば日付データを作らない
      if (emptyKey) {
        return;
      }
      // すべての日付が入力されたら、日付として有効かどうかをチェックする。
      const strValue = `${data.ymd} ${data.time}:${data.minute}:${data.second}`;
      if (!moment(strValue, 'YYYY/MM/DD HH:mm').isValid()) {
        return;
      }
      if (!this.canPast) {
        const newDt = moment(strValue);
        const now = moment();
        if (newDt.isBefore(now)) {
          await this.alert('過去の日付は設定できません。');
          this.init();
          return;
        }
      }
      const param = this.updateParam ? Object.assign(this.updateParam) : {};
      param.value = strValue;
      this.$emit('change', param);
    },
    init() {
      this.timeObj = {
        ymd: '',
        time: '',
        minute: '',
        second: '00',
      };
      if (this.noTime) {
        this.timeObj.time = '00';
      }
      if (this.noMinute) {
        this.timeObj.minute = '00';
      }
    },
    setData(value) {
      const t = moment(value, 'YYYY/MM/DD HH:mm').isValid() ? value : null;
      if (t === null) {
        this.init();
        return;
      }
      this.timeObj.ymd = moment(value).format('YYYY/MM/DD');
      this.timeObj.time = moment(value).format('HH');
      this.timeObj.minute = moment(value).format('mm');
      this.timeObj.second = '00';
    },
  },
  computed: {
    setNewDate() {
      if (this.openDate) {
        return new Date(this.openDate);
      }
      return this.openDate;
    },
  },
  created() {
    this.timeOption = TimeOptionHelper.getTimeOptionArray();
    this.minuteOption = TimeOptionHelper.getMinuteOptionArray();
    if (this.value) {
      const timeObj = {
        ymd: moment(this.value).format('YYYY/MM/DD'),
        time: moment(this.value).format('HH'),
        minute: moment(this.value).format('mm'),
        second: moment(this.value).format('ss'),
      };
      this.timeObj = timeObj;
    }
    // 分なし設定の場合、0分固定
    if (this.noTime) {
      this.timeObj.time = '00';
    }
    if (this.noMinute) {
      this.timeObj.minute = '00';
    }
  },
};
</script>
<style>
input.w-small{
  width:175px;
}

.calendar-datetime-icon {
  position: relative;
  left: -32px;
}

.news-calendar-datetime-icon {
  position: relative;
  left: -32px;
  top: 12px;
}

.calendar-datetime-hide-button {
  margin-left: 170px;
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  right: 1px;
  z-index: 1;
}
</style>
<style scoped>
.line {
  align-items: center;
  margin:10px 0 !important;
}
.line label {
  align-items:center;
  margin:0;
}
.line .right {
  text-align: right;
}
.badgeWrap,
.label{
  display: inline-block;
}
.badgeWrap div{
  vertical-align: middle;
}
div.pale .badge-required {
  opacity: 0.4;
}
.error {
  display:block;
  text-align: left;
  padding-left: 145px;
  margin-top: -6px;
  font-size: 80%;
  color: #dc3545;
}
input::-ms-clear {
  visibility:hidden
}

input.calendar {
  margin-right: 10px !important;
}
</style>
